export const MFA_GET = "[MFA] Get";
export const MFA_GET_SUCCESS = "[MFA] Get Success";
export const MFA_GET_FAIL = "[MFA] Get Fail";
export const MFA_GET_RESET = "[MFA] Get Reset";

export const MFA_ENABLE = "[MFA] Enable";
export const MFA_ENABLE_SUCCESS = "[MFA] Enable Success";
export const MFA_ENABLE_FAIL = "[MFA] Enable Fail";
export const MFA_ENABLE_RESET = "[MFA] Enable Reset";

export const MFA_DISABLE = "[MFA] Disable";
export const MFA_DISABLE_SUCCESS = "[MFA] Disable Success";
export const MFA_DISABLE_FAIL = "[MFA] Disable Fail";

export const MFA_GOOGLE_INFO_GET = "[MFA] Google INFO GET";
export const MFA_GOOGLE_INFO_GET_SUCCESS = "[MFA] Google INFO GET Success";
export const MFA_GOOGLE_INFO_GET_FAIL = "[MFA] Google INFO GET Fail";
